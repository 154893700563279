/* global $ */

class App
{
    constructor()
    {
        this.menuMoving = false;
        this.disableLogger();
        this.initBannerSlider();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms();
        this.initFontAwesome();
        this.initMatrixContent();
        this.initStickyNav();
        $('body').css('opacity', 1);
        $(window).scroll(() => {
            this.initStickyNav();
        });
    }
    initBannerSlider() {
        if ($(".banner-slide").length > 1) {
            import(/* webpackChunkName: "slick" */ './components/slick').then(() => {
                $('.banner-slider').slick({
                    autoplay: true,
                    arrows: false,
                    dots: false,
                    rows: 0,
                    fade: true,
                    speed: 1500,
                    autoplaySpeed: 6000,
                    cssEase: 'linear'
                });
            });
        }
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }

    initFontAwesome()
    {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initForms()
    {
        if ($('form').length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($('form.js-validate'), (i, elem) => {
                    new chunk.Form(elem.id);
                });
            });
        }
        if ($('form .datepicker').length) {
            import(/* webpackChunkName: "flatpickr" */ './components/flatpickr').then((chunk) => {
                $.each($(".datepicker"),(i, elem) => {
                    chunk.flatpickr(elem, $(elem).data('options'));
                });
            });
        }
    }

    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    initToasts(elems)
    {
        this.getBootstrap().then((chunk) => {
            $.each(elems, function (i, elem) {
                new chunk.Toast(elem);
            });
        });
    }

    initMatrixContent()
    {
        if ($('.matrix-block').length) {
            import(/* webpackChunkName: "matrixContent" */ './components/matrixContent');
        }
    }

    initTooltips(elems)
    {
        this.getBootstrap().then((chunk) => {
            $.each(elems, function (i, elem) {
                new chunk.Tooltip(elem);
            });
        });
    }
    initStickyNav() {
        var headerHeight = $(".header-top").height();
        var scrollTop = $(window).scrollTop();
        if (scrollTop >= headerHeight) {
            $('header').addClass('sticky');
        } else {
            $('header').removeClass('sticky');
        }
    }
}

export default App;